body {
  margin: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
/* :root{
  --sidebar-color: red;
  } */